<template>
    <div>
        <modal-confirm ref="modalConfirm" :title="confirmModalTitle" :message="confirmModalMessage" @close="confirmModalOnClose" @confirm="confirmModalOnConfirm" />
        <div class="container">
            <div v-if="cateringOrder">
                <div class="catering-order">
                    <h2>Porudzbina: #{{ cateringOrder.id }}</h2>
                    <h4 class="">Broj obroka: <strong>{{ cateringOrder.meal_count }}</strong></h4>
                    <p class="mb-0">Lokacija: <strong>{{  cateringOrder.work_order_name }}</strong></p>
                    <p class="mb-0">Restoran: <strong>{{ cateringOrder.catering_service_name }} (<a :href="'tel:' + cateringOrder.catering_service.phone">{{ cateringOrder.catering_service.phone }}</a>)</strong></p>
                    <p class="mb-0">Poručilac: <strong>{{  cateringOrder.purchaser.first_name }} {{  cateringOrder.purchaser.last_name }} (<a :href="'tel:' + cateringOrder.phone_number">{{ cateringOrder.phone_number }}</a>)</strong></p>
                    <p class="mb-0">Cena: <strong>{{ cateringOrder.unit_price }} * {{ cateringOrder.meal_count }} = {{ cateringOrder.unit_price * cateringOrder.meal_count }}</strong></p>
                </div>

                <div class="status mb-4">
                    <h2>Status: {{ getCateringOrderStatusLabel(cateringOrder.status) }}</h2>
                    <div>
                        <div class="btn-group flex-wrap" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" class="btn-check" name="catering-order" id="co-ordered" autocomplete="off" v-model="cateringOrder.status" disabled value="ORDERED">
                            <label class="btn btn-xlg btn-outline-primary" for="co-ordered">Poručeno</label>

                            <input type="radio" class="btn-check" name="catering-order" id="co-in-progress" autocomplete="off" v-model="cateringOrder.status" @click="changeToInprogress" value="IN_PROGRESS">
                            <label class="btn btn-xlg btn-outline-primary" for="co-in-progress">Prihvaćeno</label>

                            <input type="radio" class="btn-check" name="catering-order" id="co-delivered" autocomplete="off" v-model="cateringOrder.status" @click="changedToDelivered" :disabled="!isManager" value="DELIVERED">
                            <label class="btn btn-xlg btn-outline-primary" for="co-delivered">Dostavljeno</label>
                        </div>
                    </div>
                    <div v-if="cateringOrder.status != 'CANCELED'" class="btn btn-outline-secondary" @click="changedToCanceled"><template v-if="cateringOrder.status == 'CANCELED'">ODBIJENO</template><template v-else>Odbij porudžbinu</template></div>
                </div>

                <div v-if="cateringOrder.work_order.location_longitude && cateringOrder.work_order.location_latitude">
                    <h5>Lokacija</h5>
                    <info-map :initalWorkOrder="cateringOrder.work_order" :readOnly="true" />
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>
    import CateringService from '@/service/CateringService.js';
    import InfoMap from '../maps/InfoMap.vue';
    import ModalConfirm from '../modals/ModalConfirm.vue';
    import {mapGetters} from 'vuex'


    export default {
        components: { InfoMap, ModalConfirm },
        data() {
  
            return {
                cateringOrder: null,
                confirmModalTitle: "",
                confirmModalMessage: "",
                confirmModalOnClose: "",
                confirmModalOnConfirm: "",
            }
        },

        computed: {
            ...mapGetters([
                'isManager',
            ]),

            cateringOrderId() {
                return parseInt(this.$route.params.cateringOrderId);
            },

            secretCode() {
                return this.$route.query.c
            }

        },

        methods: {
            getCateringOrderStatusLabel: CateringService.getCateringOrderStatusLabel,

            async loadCateringOrder() {
                let payload = {
                    url: { id: this.cateringOrderId },
                    headers: {"access-token": this.secretCode}
                }

                try {
                    let result = await CateringService.getCateringOrder(payload);
                    this.cateringOrder = result.data
                } catch {
                    this.$toasted.show("Neuspešno učitavanje narudžbine", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }
            },

            changeToInprogress(event) {
                event.preventDefault()
                this.confirmModalTitle = "Potvrda porundzbine"
                this.confirmModalMessage = "Da li ste sigurni da želite da promenite status porudžbine u PRIHVAĆENO"
                this.confirmModalOnClose = function() {}
                this.confirmModalOnConfirm = this.updateToInprogress
                this.$refs.modalConfirm.showModal()


            },

            changedToDelivered(event) {
                event.preventDefault()
                this.confirmModalTitle = "Potvrda dostave"
                this.confirmModalMessage = "Da li ste sigurni da želite da promenite status u DOSTAVLJENO"
                this.confirmModalOnClose = function() {}
                this.confirmModalOnConfirm = this.updateToDelivered
                this.$refs.modalConfirm.showModal()


            },

            changedToCanceled(event) {
                event.preventDefault()
                this.confirmModalTitle = "Odbijanje porundzbine"
                this.confirmModalMessage = "Da li ste sigurni da želite da odbijete porudžbinu"
                this.confirmModalOnClose = function() {}
                this.confirmModalOnConfirm = this.updateToCanceled
                this.$refs.modalConfirm.showModal()


            },

            async updateToInprogress() {
                let payload = {
                    url: { id: this.cateringOrderId },
                    headers: {"access-token": this.secretCode}
                }

                try {
                    await CateringService.putCateringOrderInProgress(payload);
                    this.$toasted.show("Uspešno ažurirana narudžbina", { 
                        type: 'success',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                    this.loadCateringOrder()
                } catch {
                    this.$toasted.show("Neuspešno učitavanje narudžbine", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }
            },

            async updateToDelivered() {
                let payload = {
                    url: { id: this.cateringOrderId },
                    headers: {"access-token": this.secretCode}
                }

                try {
                    await CateringService.putCateringOrderDelivered(payload);
                    this.$toasted.show("Uspešno ažurirana narudžbina", { 
                        type: 'success',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                    this.loadCateringOrder()
                } catch {
                    this.$toasted.show("Neuspešno učitavanje narudžbine", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }
            },


            async updateToCanceled() {
                let payload = {
                    url: { id: this.cateringOrderId },
                    headers: {"access-token": this.secretCode}
                }

                try {
                    await CateringService.putCateringOrderCanceled(payload);
                    this.$toasted.show("Uspešno ažurirana narudžbina", { 
                        type: 'success',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                    this.loadCateringOrder()
                } catch {
                    this.$toasted.show("Neuspešno učitavanje narudžbine", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }
            }
        },

        created() {
            this.loadCateringOrder()
        }

    }
</script>

<style scoped>

    @media (min-width: 806px) {
        .btn-xlg{
            padding: 1.5em 3em;
            font-size: 1.25rem;
            border-radius: 0.3rem;
        }
    }

</style>