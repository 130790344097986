<template>
    <div class="worker-jobs">
        <Navbar />
        <Breadcrumbs></Breadcrumbs>
        <catering-order-link-view />
        <Footer />
        <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue';
import CateringOrderLinkView from '../../components/catering/CateringOrderLinkView.vue';


export default {
    name: 'Home',
    components: {
        Navbar,
        Footer,
        CateringOrderLinkView,
    },
}
</script>

<style scoped>

</style>
